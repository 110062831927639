@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: "bignote";
  src: url("./assets/fonts/bigtech.woff");
}

@font-face {
    font-family: "Bigtech";
    src: url("./assets/fonts/KFOmCnqEu92Fr1Mu4mxK.woff2");
  }

  @font-face {
    font-family: "smalltech";
    src: url("./assets/fonts/l.woff2");
  }

  @font-face {
    font-family: "lvreg";
    src: url("./assets/fonts/LouisVuitton-Regular.woff2");
  }

  @font-face {
    font-family: "lvbold";
    src: url("./assets/fonts/LouisVuitton-Bold.woff2");
  }

  @font-face {
    font-family: "roboto";
    src: url("./assets/fonts/KFOmCnqEu92Fr1Mu4mxK.woff2");
  }

  @font-face {
    font-family: "poppinsreg";
    src: url("./assets/fonts/Poppins/Poppins-Regular.ttf");
  }

  @font-face {
    font-family: "worksans";
    src: url("./assets/fonts/Work_Sans/static/WorkSans-Regular.ttf");
  }
  
  @layer base {
    .ql-size-huge{
      font-size: 2.0rem !important;
    }
    
  }
  